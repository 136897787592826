//Tabs Navigation
const FORM_LABELS = Object.freeze({
    email: 'email',
    password: 'password',
})

const FORM_BUTTONS = Object.freeze({
    send: 'send',
})

export {
    FORM_LABELS,
    FORM_BUTTONS,
}