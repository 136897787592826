<template lang="pug">
    div.auth
        div.auth__top
            div.top__close
                button.close__button(@click="showLogin()")
        div.auth__content(v-if="isLogin")
            div.content__login
                p.login__title авторизация
                //- p.login__text для участия в акции необходима регистрация
                //- button.button-pink.login__button(@click="showContent('isRegistration')") зарегистрироваться
                //- p.login__text.mt-12 если ты уже зарегистрирован, укажи свою электронную почту для входа в личный кабинет
                //- p.login__text.mt-4 элетронная почта
                input.login__email(v-model="email", placeholder="Почта", v-on:keyup.enter="onLogin()", type="email")
                input.login__email(placeholder="Пароль", v-model="password" v-on:keyup.enter="onLogin()" type="password")
                button.button-pink.login__button(@click="onLogin()" v-if="!isLoading") авторизоваться
                div
                    div.lds-dual-ring(v-if="isLoading")

        div.auth__content(v-if="isInfo")
            div.content__login
                p.login__title авторизация
                p.login__text.mt-18 Пользователь с таким -e-mail не найден.
                p.login__text.mt-5 Пожалуйста, зарегистрируйся, чтобы участвовать в конкурсе
                button.button-pink.login__button.mt-16.mb-16(@click="showContent('isRegistration')") зарегистрироваться

        div.auth__content(v-if="isRegistration")
            div.content__login
                p.login__title регистрация
                div.grid.grid-cols-1.gri.gap-4
                    div
                        span.grid__lable элетронная почта
                        input.login__reg(v-model="params.email", type="email")
                div.grid.grid-cols-2.gri.gap-4
                    div
                        span.grid__lable пароль
                        input.login__reg(v-model="params.password", type="password")
                    div
                        span.grid__lable пароль еще раз
                        input.login__reg(v-model="repeatPassword", type="password")

                div.login__tip Длина пароля не менее 8 символов


                div.login__check
                    input.check__box(v-model="params.rules_agreement", type="checkbox") 
                    p.login__text(@click="params.rules_agreement  = (params.rules_agreement ? false : true)")
                        span Я Согласен с 
                        a(href="https://jac.ru.tv/api/v1/uploads/files/2022-11-30/1669809946592-28.11.pdf") правилами проведения акции

                div.login__check
                    input.check__box(v-model="params.personal_data_processing_agreement", type="checkbox") 
                    p.login__text(@click="params.personal_data_processing_agreement  = (params.personal_data_processing_agreement ? false : true)") Я Согласен с обработкой персональных данных

                div.login__check
                    input.check__box(v-model="params.personal_data_share_agreement", type="checkbox") 
                    p.login__text(@click="params.personal_data_share_agreement  = (params.personal_data_share_agreement ? false : true)") Я Согласен с передачей персональных данных

                div.login__check
                    input.check__box(v-model="params.confidentiality_agreement", type="checkbox") 
                    p.login__text(@click="params.confidentiality_agreement  = (params.confidentiality_agreement ? false : true)") 
                        span Я Согласен с 
                        a(href="https://ru.tv/policy-privacy") политикой конфиденциальности


                div.login__btns.flex(v-if="!isLoading")
                    button.button-pink.login__back.mt-16.mb-16(@click="showContent('isLogin')") назад
                    button.button-pink.login__registration(@click="showContent('isRegistration2')") далее
                div
                    div.lds-dual-ring(v-if="isLoading")




        div.auth__content(v-if="isRegistration2")
            div.content__login
                p.login__title регистрация
                div.grid.grid-cols-1.gri.gap-4
                    div
                        span.grid__lable имя
                        input.login__reg(v-model="params.name", type="text")
                div.grid.grid-cols-1.gri.gap-4
                    div
                        span.grid__lable номер телефона
                        input.login__reg(v-model="params.phone", v-mask="'+7(###)###-##-##'")
                div.login__btns.flex(v-if="!isLoading")
                    button.button-pink.login__back.mt-16.mb-16(@click="showContent('isRegistration')") назад
                    button.button-pink.login__registration(@click="registration()") зарегистрироваться
                div
                    div.lds-dual-ring(v-if="isLoading")
</template>

<script>
//Store
import { AuthorizationNameSpace, AuthorizationActionTypes } from '@/store/authorization/types';
import { RegistrationNameSpace, RegistrationActionTypes } from '@/store/registration/types';
//Config

import { notifyError, notifySuccess, notifyDefaultError } from '../../../helpers/notifications';
import { FORM_LABELS, FORM_BUTTONS } from '@/configs/forms'

export default {
    name: "auth",
    components: {notifyError, notifySuccess, notifyDefaultError},
    data() {
        return {
            email: '',
            password: '',
            isPassword: false,
            isLogin: true,
            isInfo: false,
            isRegistration: false,
            isRegistration2: false,
            isLoading: false,
            repeatPassword: '',
            params: {
                name                               : '',
                email                              : '',
                password                           : '',
                rules_agreement                    : false,
                personal_data_processing_agreement : false,
                personal_data_share_agreement      : false,
                confidentiality_agreement          : false
            }
        }
    },
    computed: {
        FORM_LABELS() {
            return FORM_LABELS
        },
        FORM_BUTTONS() {
            return FORM_BUTTONS
        },
    },
    mounted() {
        if (localStorage.getItem('email')) {
            this.onLoginAfterRegistration()
            localStorage.removeItem('email')
        }
    },
    methods: {
        showLogin(){
            this.$emit('showLogin', false)
        },
        showContent(type){
            if (this.isLoading) {
                return true
            }

            if (type == 'isRegistration2') {
                if (!this.params.email) {
                    notifyError({ title: 'Регистрация', errors: 'Введите Email пожалуйста' })
                    return false
                } else if (!this.validateEmail(this.params.email)) {
                    notifyError({ title: 'Регистрация', errors: 'Email введен не коректно' })
                    return false
                }  else if (this.params.password.length < 8) {
                    notifyError({ title: 'Регистрация', errors: 'Пароль должен состоять не менее чем из 8 символов' })
                    return false
                } else if (this.params.password != this.repeatPassword) {
                    notifyError({ title: 'Регистрация', errors: 'Пароль не совпадает' })
                    return false
                } else if (!this.params.rules_agreement) {
                    notifyError({ title: 'Регистрация', errors: 'Примите "Согласие с правилами проведения акции" пожалуйста' })
                    return false
                } else if(!this.params.personal_data_processing_agreement) {
                    notifyError({ title: 'Регистрация', errors: 'Примите "Согласие с обработкой персональных данных" пожалуйста' })
                    return false
                } else if (!this.params.personal_data_share_agreement) {
                    notifyError({ title: 'Регистрация', errors: 'Примите "Согласие с передачей персональных данных" пожалуйста' })
                    return false
                } else if(!this.params.confidentiality_agreement) {
                    notifyError({ title: 'Регистрация', errors: 'Примите "Согласие с политикой конфиденциальности" пожалуйста' })
                    return false
                }
            }

            this.isLogin         = false
            this.isInfo          = false
            this.isRegistration  = false
            this.isRegistration2 = false
            this[type]           = true
        },
        registration(){
            if (!this.params.name) {
                notifyError({ title: 'Регистрация', errors: 'Введите Имя пожалуйста' })
                return false
            } else if (!this.params.phone) {
                notifyError({ title: 'Регистрация', errors: 'Введите Телефон пожалуйста' })
                return false
            } else if (this.params.phone.length < 13) {
                notifyError({ title: 'Регистрация', errors: 'Введите корректный номер телефона' })
                return false
            }

            this.isLoading = true
            this.$store.dispatch(`${RegistrationNameSpace}/${RegistrationActionTypes.Register}`, { data: {...this.params} }).then(({data}) => {
                this.isLoading = false
                if (data) {
                    // this.showContent('isLogin')
                    this.email = this.params.email, this.password = this.params.password
                    // this.params = {
                    //     name      : '',
                    //     lastname  : '',
                    //     email     : '',
                    //     password  : '',
                    //     personal_data_conditions: false,
                    //     user_agreement          : false
                    // }

                    this.onLogin()
                }
            })
        },
        validateEmail(email) {
            return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/.test(email || this.email)
        },
        async onLogin(){

            if (!this.email) {
                notifyError({ title: 'Email', errors: 'Введите Email пожалуйста' })
                return false
            } else if (!this.validateEmail()) {
                notifyError({ title: 'Email', errors: 'Email введен не коректно' })
                return false
            } else if (!this.password) {
                notifyError({ title: 'Пароль', errors: 'Введите пароль пожалуйста' })
                return false
            }

            this.isLoading = true   
            this.$store.dispatch(`${AuthorizationNameSpace}/${AuthorizationActionTypes.Login}`, { 
                email    : this.email, 
                password : this.password 
            }).then((data) => {
                if (data) {
                    this.$router.push('/account')
                }
                this.isLoading = false
            }).catch((e) => {
                console.log('e', e)
                this.isLoading = false
                return e
            })
       

        },
        onEmail(value) {
            this.email = value
        },
        onPassword(value) {
            this.password = value
        },
        onLoginAfterRegistration() {
            this.email = localStorage.getItem('email')
        },
    }
}
</script>

<style lang="scss" scoped>

.auth{
    background: #0A0C19;
    width: 100%;
    min-height: 165px;
    border-radius: 10px;


    .auth__content{
        padding: 18px;
        @media (max-width:500px){
            padding: 15px;
        }

        .content__login{
            background: #FFFFFF;
            border-radius: 5px;
            padding: 5px;
            min-height: 200px;
            a {
                text-decoration: underline;
                &:hover{
                    color: #E4007A
                }
            }
                
            .grid{
                padding: 5px 20px;
                @media (max-width:500px){
                    padding: 5px 15px;
                }
                .grid__lable{
                    font-weight: 700;
                    font-size: 16px;
                    float: left;
                    margin-left: 12px;
                    text-transform: uppercase;
                    color: #0A0C19;
                    width: 100%;
                    text-align: left;
                }
            }

            .login__title{
                font-weight: 900;
                font-size: 22px;
                line-height: 20px;
                text-align: center;
                margin: 20px 0px 25px 0px;
                text-transform: uppercase;
                color: #0A0C19;
            }

            .login__text{
                font-weight: 600;
                font-size: 19px;
                line-height: 20px;
                cursor: pointer;
                text-align: center;
                text-transform: uppercase;
                color: #0A0C19;
                max-width: 255px;
                margin-left: auto;
                margin-right: auto;
            }

            .login__button{
                font-size: 23px !important;
                height: 45px !important;
                padding-bottom: 0px !important;                
                margin: 20px 0px 20px 0px !important;
                min-width: 360px !important;
                border-radius: 20px !important;
                border-radius: 18px !important;
                @media (max-width:500px){
                    min-width: 320px !important;
                    padding-left: 0px !important;
                    padding-right: 0px !important;
                }
            }

            .login__tip{
                font-size: 12px;
                color: gray;
                font-weight: 200;
            }

            .login__btns{
                width: 100%;
                overflow: auto;
                padding: 0px 20px;
                @media (max-width:500px){
                    padding-left: 15px !important;
                    padding-right: 15px !important;
                }
                .login__registration{
                    font-size: 23px !important;
                    height: 45px !important;
                    padding-bottom: 0px !important;                
                    margin: 20px 0px 20px 0px !important;
                    min-width: 254px !important;
                    width: 216px;
                    padding: 3px 0px 0px 0px !important;
                    float: left;
                    border-radius: 20px !important;
                    border-radius: 18px !important;
                    @media (max-width:500px){
                        min-width: 233px !important;
                        padding-left: 2px !important;
                        padding-right: 2px !important;
                        font-size: 20px !important;
                    }
                }

                .login__back{
                    font-size: 23px !important;
                    height: 45px !important;
                    padding-bottom: 0px !important;                
                    margin: 20px 15px 20px 0px !important;
                    min-width: 95px !important;
                    float: left;
                    display: inline-block;
                    padding: 3px 0px 0px 0px !important;
                    border-radius: 20px !important;
                    border-radius: 18px !important;
                    @media (max-width:500px){
                        min-width: 82px !important;
                        padding-left: 2px !important;
                        padding-right: 2px !important;
                        font-size: 20px !important;
                        margin-right: 5px !important;
                    }
                }
            }

            .login__email{
                background: #FFFFFF;
                border: 1px solid #D9D9D9;
                border-radius: 13px;
                box-shadow: 0px -4px 0px rgb(230 0 126 / 15%);
                min-width: 330px;
                margin: 20px 0px 0px 0px;
                height: 40px;
                padding: 0px 20px;
                font-size: 22px;
                text-align: center;
                color: #9C9A9A;
            }

            .login__reg{
                background: #FFFFFF;
                border: 1px solid #D9D9D9;
                border-radius: 13px;
                box-shadow: 0px -4px 0px rgb(230 0 126 / 15%);
                margin: 6px 0px 0px 0px;
                height: 40px;
                width: 100%;
            }

            .login__check{
                width: 100%;
                float: left;
                padding: 15px 20px 5px 20px;
                @media (max-width:500px){
                    padding: 15px 15px 5px 15px;
                }
                .check__box{
                    display: inline-block;
                    float: left;
                    margin-right: 10px;

                }
                .login__text{
                    text-align: left;
                    float: left;
                    display: inline-block;
                    max-width: 84% !important;
                }
            }

            .login__btns{
                width: 100%;
                overflow: auto;
            }

        }
    }
    .auth__top{
        background-image: url(./assets/mobilebacklogo.png);
        background-repeat: no-repeat;
        width: 100%;
        min-height: 165px;
        background-size: 80%;
        border-radius: 10px;
        @media (max-width:500px){
            min-height: 100px;
            background-size: 70%;
        }
        .top__close{
            float: right;
            .close__button{
                border: 2px solid #FFF;
                cursor: pointer;
                width: 55px;
                height: 55px;
                background: none;
                font-weight: 400;
                font-size: 6px;
                line-height: 62px;
                text-align: center;
                text-transform: uppercase;
                color: #FFFFFF;
                border-radius: 100px;
                background-image: url(./assets/close.png);
                background-repeat: no-repeat;
                background-size: 45%;
                background-position: 15px 14px;
                margin-top: 105px;
                margin-right: 18px;
                @media (max-width:500px){
                    margin-top: 50px;
                    background-position: 13px 13px;
                    width: 45px;
                    height: 45px;
                }       
                &:hover{
                    opacity: 0.8;
                }
            }

        }

    }
}

</style>