<template lang="pug">
    div.footer
        div.footer__top
        div.footer__bottom 
            div.bottom__block
                a.footer_logo(href="javascript:void(0)" @click="goToHome()")
                a.bottom__text(href="mailto:support@ru.tv") Поддержка support@ru.tv
                a.bottom__text(href="https://jac.ru.tv/api/v1/uploads/files/2022-11-30/1669809946592-28.11.pdf" target="_blank") правила акции
                a.bottom__text(href="javascript:void(0)" @click="toLK()") личный кабинет
</template>

<script>

import $ from 'jquery'

export default {
    name: "footer",
    data () {
      return {
      }
     },
    methods: {
        goToHome() {
            localStorage.setItem('showHome', 1)
            window.location.replace('/')
        },
        toLK(){
            let loggedIn = window.localStorage.getItem('loggedIn')
            if (loggedIn) {
                window.location.replace('/account')
            } else {
                $('html, body').animate({scrollTop: 600}, 500);
                setTimeout(() => {
                    $('.login-trigger').trigger('click')
                }, 600)
            }
        }
    },
}
</script>

<style lang="scss" scoped>

.footer{
    height: 700px;
    display: flex;
    flex-direction: column;
    background-image: url(./assets/footer_presents.svg);
    background-position: 0px -301px;
    background-repeat: no-repeat;
    @media (max-width:500px){
        height: 333px;
        background-position: 0px 3px;
        background-size: 100%;
    }

    .footer__top{
        height: 100%;
    }

    .footer__bottom{
        background: #E4007A;
        height: 325px;
        /* padding: 100px 100px; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 100px;
        padding-top: 20px;
        @media (max-width:500px){
            height: 150px;
            padding-left: 30px;
            padding-top: 25px;;
        }
        .bottom__block{
            display: flex;
            flex-direction: column;
            position: relative;

             .footer_logo{
                position: absolute;
                width: 180px;
                height: 100px;
                background-size: 100%;
                right: 85px;
                top: 22px;
                display: block;
                background-image: url(./assets/whitelogo.svg);
                background-repeat: no-repeat;
                &:hover{
                    opacity: 0.8;
                }
                @media (max-width:500px){
                    width: 80px;
                    height: 40px;
                    right: 15px;
                    top: 15px;
                }
            }

            .bottom__text{
                font-family: 'Pancetta Pro';
                font-style: normal;
                font-weight: 900;
                font-size: 24px;
                line-height: 110%;
                margin-bottom: 25px;
                text-transform: uppercase;
                color: #FFFFFF;
                @media (max-width:500px){
                    font-size: 14px;
                    margin-bottom: 15px;
                }
            }
        }
        
    }

}


</style>