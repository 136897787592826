<template lang="pug">
    div.wrapper
        div.wrapper__content
            _header
            section
                slot(name="main")
            _footer
</template>

<script>
//Components
import _header from '@/components/common/header';
import _footer from '@/components/common/footer';

export default {
    name: "base-layout",
    components: {
        _header, _footer
    },
    computed: {
    }
}
</script>

<style lang="scss" scoped>

.wrapper{
    font-family: 'Pancetta Pro';
    
    background-image: url(./assets/footer_snowzinki.svg);
    background-repeat: no-repeat;
    background-position-y: 61%;
    background-position-x: 825px;

    @media (max-width:500px){
        background-position-y: 110%;
        background-position-x: 14%;
    }
    .wrapper__content{
        max-width: 1366px;
        margin: auto;
        section{
            min-height: 500px;
        }
    }
}

.router-link-exact-active {
    color: #2563eb;
    text-decoration: none;
    cursor: default;
}
</style>