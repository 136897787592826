<template lang="pug">
    div.header
        div.header__snowflakes
            div.snowflakes__snow
                div.snow__middle
                    a.middle__logo(href="javascript:void(0)" @click="goToHome()")
                    div.middle__top
                        p.top__text1 с 1 по 30 декабря 2022 г. 
                        p.top__text2 #ЛОВИНОВЫЙГОД
                        p.top__text3 и получи новый автомобиль!
                        div.inform__auth
                            div.auth__block.auth__mobile(v-show="isShowLogin2")
                                auth(v-on:showLogin="showLogin")
                        //- button.button-pink.mb-24(v-if="(loggedIn && showHome) || (!loggedIn && !showHome)" @click="showLogin(undefined, true)") участвовать!
                    div.middle__tree
                    div.middle__car
</template>

<script>
import auth from '@/components/common/auth';

export default {
    name: "header",
    components: {
        auth
    },
    data: function(){
        return {
            loggedIn: false,
            showHome: false,
            isShowLogin: false,
            isShowLogin2: false
        }
    },
    mounted(){
        this.loggedIn = JSON.parse(localStorage.getItem('loggedIn'))
        this.showHome = JSON.parse(localStorage.getItem('showHome'))
    },
    methods: {
        goToHome() {
            localStorage.setItem('showHome', 1)
            window.location.replace('/')
        },
        showLogin(flag, isTwo){

            if (this.loggedIn) {
                window.location.replace('/account')
                return false
            }

            const el = document.querySelector('.auth__mobile')
            el.style.top = `${window.scrollY}px`

            if (flag != undefined) {
                this.isShowLogin  = flag
                this.isShowLogin2 = flag
            } else {
                if (isTwo) {
                    this.isShowLogin2 = this.isShowLogin2 ? false : true
                    this.isShowLogin  = false
                } else {
                    this.isShowLogin  = this.isShowLogin ? false : true
                    this.isShowLogin2 = false
                }
                
            }

        }
       
    },
}
</script>

<style lang="scss" scoped>

.header{
    background-color: rgba(230, 0, 126, 0.20);
    min-height: 720px;
    @media (max-width:500px){
        min-height: 575px;
    }

    .header__snowflakes{
        background-image: url(./assets/snowzinki.svg);
        background-repeat: no-repeat;
        background-position: 50px 0px;
        @media (max-width:500px){
            background-position: -872px -111px;
        }

        .snowflakes__snow{
            background-image: url(./assets/snow.svg);
            background-repeat: no-repeat;
            background-position: 0px 102px;
            @media (max-width:500px){
                background-position: 0px 399px;
                background-size: 100%;
            }
            .snow__middle{
                position: relative;
                max-width: 630px;
                min-height: 720px;
                margin: auto;
                @media (max-width:500px){
                    min-height: 575px;
                }

                .middle__top{
                    padding-top: 50px;
                    text-align: center;
                    text-transform: uppercase;
                    font-weight: 900;
                    min-height: 260px;
                    font-family: 'Pancetta Pro';
                    .inform__auth{
                        // position: relative;
                        max-width: 400px;
                        margin-left: auto;
                        margin-right: auto;
                        display: none;
                        @media (max-width:500px){
                            margin-left: 0;
                            max-width: 390px;
                            display: block;
                        }
                        .auth__block{
                            position: absolute;
                            z-index: 5;
                            top: -100px;
                            width: 450px;
                            left: -5%;
                            @media (max-width:500px){
                                top: -10px;
                                width: 100%;
                                left: auto;
                            }
                        }
                    }
                    .button-pink{
                        font-size: 18px;
                        height: 32px;
                        padding: 5px 35px 5px 35px !important;
                        display: none;
                        margin: auto;
                        @media (max-width:500px){
                            display: block;
                        }
                    }
                    .top__text1{
                        font-size: 35px;
                        color: #E4007A;
                        margin-bottom: 40px;
                        @media (max-width:500px){
                            font-size: 23px;
                            color: #E4007A;
                            margin-bottom: 15px;
                            margin-top: 110px;
                        }
                    }
                    .top__text2{
                        font-size: 65px;
                        color: #E4007A;
                        position: relative;
                        z-index: 1;
                        @media (max-width:500px){
                            font-size: 40px;
                            line-height: 35px;
                        }
                    }
                    .top__text3{
                        font-size: 35px;
                        color: #000;
                        position: relative;
                        z-index: 1;
                        @media (max-width:500px){
                            font-size: 25px;
                            margin-bottom: 15px
                        }
                    }
                }
                
                .middle__logo{
                    background-image: url(./assets/logo_rutv.svg);
                    background-repeat: no-repeat;
                    position: absolute;
                    display: block;
                    right: -280px;
                    top: 20px;
                    width: 160px;
                    height: 80px;
                    &:hover{
                        opacity: 0.8;
                    }
                    @media (max-width:1300px){
                        right: -150px;
                    }
                    @media (max-width:500px){
                        right: 0px;
                        top: 20px;
                        width: 136px;
                        height: 80px;
                        background-size: 80%;
                    }
                }

                .middle__tree{
                    background-image: url(./assets/header_tree.png);
                    background-repeat: no-repeat;
                    position: absolute;
                    left: -300px;
                    bottom: 35px;
                    width: 420px;
                    height: 620px;
                    @media (max-width:1245px){
                        left: -150px;
                    }
                    @media (max-width:500px){
                        right: 144px !important;
                        left: auto;
                        bottom: 40px;
                        width: 220px;
                        height: 200px;
                        background-size: 55%;
                    }
                }

                .middle__car{
                    background-image: url(./assets/header_car.svg);
                    background-repeat: no-repeat;
                    position: absolute;
                    right: -250px;
                    bottom: 40px;
                    width: 730px;
                    background-position: 0px -135px;
                    height: 330px;
                    @media (max-width:1245px){
                        right: -220px;
                    }
                    @media (max-width:500px){
                        right: 7px;
                        bottom: 15px;
                        width: 277px;
                        background-position: 0px 0px;
                        height: 180px;
                        background-size: 100%;
                    }

                }
            }

        }

    }

}


</style>